<template>
    <div class="inner_pages">
        <!-- <inner-sidebar /> -->
        <div class="respective_content"  id="template_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Templates</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                    <!-- <button type="button" class="add_btn pointer"  @click="newTemplate = true"><i class="fas fa-plus"></i>Add Template</button> -->
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="createCategory = !createCategory" v-tooltip="`Create Templates Category`" position="bottom"><img src="@/assets/images/grid.svg"></li>
                        <li @click="sortTemplate = !sortTemplate" v-tooltip="`Sort Templates Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'templates')" @click="importTemplate = !importTemplate" v-tooltip="`Import a shared template`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer"  @click="newTemplate = true"><i class="fas fa-plus-circle"></i>Create Template</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedTemplate.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedTemplate.length ? `${selectedTemplate.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedTemplate.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'templates')" @click="handleChangeCategory()">Change Category</li>
                                    <li v-if="companyUserCan('create', 'templates')" @click="handleDuplicateTemplate(selectedTemplate)">Duplicate</li>
                                    <li v-if="companyUserCan('create', 'broadcasts')" @click="handleBroadcastDraft(selectedTemplate)">Add as a broadcast draft</li>
                                    <li v-if="companyUserCan('delete', 'templates')" @click="handleDeleteTemplate(selectedTemplate)" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the templates feature</li>
                                </ul>
                            </div>
                        </li>
                        <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                            <button class="search_btn" @click="searchField = !searchField;">
                                <i class="fas fa-search"></i>
                            </button>
                        </li> -->
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="templateTypeDropdown = !templateTypeDropdown" v-click-outside="closeTemplateFilterDropdown">
                            {{ templateTypeTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="templateTypeDropdown ? 'active' : ''">
                                <ul>
                                    <li v-for="(templateType, t) in templateTypeFilters" :key="t" :class="{'active' : templateType.title == templateTypeTitle}" @click="sortingTemplateFilter(templateType)">
                                        {{ templateType.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="categoryListDropdown = !categoryListDropdown"  v-click-outside="closeCategoryListDropdown" v-if="categoryList && categoryList.length">
                            {{ categoryTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr limit_height" :class="categoryListDropdown ? 'active' : ''">
                                <ul>
                                    <li v-for="(category, c) in categoryList" :key="c" :class="{'active' : category.type == categoryTitle}" @click="sortingCategory(category)">
                                        {{ category.type }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="template-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                        <!-- <li class="list_info">
                            {{ templates.from ? templates.from : 0 }} - {{ templates.to ? templates.to : 0 }} of <span>{{ templates.total ? templates.total : 0 }}</span>
                        </li> -->
                    </ul>
                </div>
                <div class="listing_loader" v-show="loader"><quote-loader /></div>
                <div class="action_content" v-show="!loader">
                    <asset-folder v-model="assetFolder" title="Template" asset-type="templates" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :section-breadcrumb="folderBreadcrumb['templates']" />
                    <ul class="playbook_list" v-if="!isArchived || folderParams.parent_id > 0">
                        <li v-for="(template, t) in templates.data" :key="t">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedTemplate.includes(template.id) }">
                                    <img v-if="template.dashboard_thumb" :src="template.dashboard_thumb" class="img">
                                    <img v-else src="@/assets/images/thumb/default-temp.svg" class="img">
                                    <span v-if="companyUserCan('update', 'templates')" class="replace_img" @click="editThumbnail = true; activeTemplate = template;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`template-${template.id}`" class="checkbox">
                                                <input type="checkbox" :id="`template-${template.id}`" :value="template.id" v-model="selectedTemplate" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ template.total_send ? template.total_send : 0 }} Sent</li>
                                        <li>{{ template.total_opens ? template.total_opens : 0 }} Opens</li>
                                        <li>{{ template.total_clicks ? template.total_clicks : 0 }} Clicks</li>
                                        <li>{{ template.open_rate ? template.open_rate : 0 }}% Open Rate</li>
                                        <li>{{ template.click_rate ? template.click_rate : 0 }}% Click Rate</li>
                                    </ul>
                                </div>
                                <div v-tooltip="template.title">
                                    <a class="item_ttl pointer" @click="editTemplate = true; activeTemplate = template;">{{ template.title }}</a>
                                </div>
                                <div class="item_info">
                                    <label class="category">{{ template.category }}</label>
                                    <ul>
                                        <li @click="moreOption(template.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  template.id}">
                                                <li v-if="companyUserCan('update', 'templates')" @click="editTemplate = true; activeTemplate = template">Edit</li>
                                                <li v-if="companyUserCan('update', 'templates')" @click="editThumbnail = true; activeTemplate = template;">Edit Thumbnail Image</li>
                                                <li v-if="companyUserCan('update', 'templates')" @click="renameTemplate = true; activeTemplate = template">Rename</li>
                                                <li @click="moveTemplate = true; activeTemplate = template">Move to Folder</li>
                                                <li @click="shareLocation = true; activeTemplate = template" >Share to Location</li>
                                                <!-- <li @click="analytics = true">Show Analytics</li> -->
                                                <li @click="handleShareTemplate(template)">Share Template</li>
                                                <li v-if="companyUserCan('create', 'templates')" @click="handleDuplicateTemplate([template.id])">Duplicate</li>
                                                <li v-if="companyUserCan('delete', 'templates')" @click="handleDeleteTemplate([template.id])" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="empty_section" v-if="!templates.total">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Looks like you don't have any Templates yet. Click the play button to get started.</h1>
                    </div> -->
                    <div class="empty_box" v-if="!templates.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Templates yet. Click the play button to get started.</h4>
                    </div>
                    <!-- <div class="pagination" v-show="templates.total && (!isArchived || folderParams.parent_id > 0)">
                        <pagination v-model="page" :pages="templates.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div> -->
                </div>
                <div class="list_footer">
                    <ul>
                        <!-- <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="template-per-page-filter" />
                        </li> -->
                        <li>{{ templates.from ? templates.from : 0 }} - {{ templates.to ? templates.to : 0 }} of {{ templates.total ? templates.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="templates.total && (!isArchived || folderParams.parent_id > 0)">
                    <pagination v-model="page" :pages="templates.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="createCategory = !createCategory" v-tooltip="`Create Templates Category`" position="bottom"><img src="@/assets/images/grid.svg"></li>
                        <li @click="sortTemplate = !sortTemplate" v-tooltip="`Sort Templates Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'templates')" @click="importTemplate = !importTemplate" v-tooltip="`Import a shared template`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedTemplate.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedTemplate.length ? `${selectedTemplate.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedTemplate.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'templates')" @click="handleChangeCategory()">Change Category</li>
                                    <li v-if="companyUserCan('create', 'templates')" @click="handleDuplicateTemplate(selectedTemplate)">Duplicate</li>
                                    <li v-if="companyUserCan('create', 'broadcasts')" @click="handleBroadcastDraft(selectedTemplate)">Add as a broadcast draft</li>
                                    <li v-if="companyUserCan('delete', 'templates')" @click="handleDeleteTemplate(selectedTemplate)" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="templateTypeDropdown2 = !templateTypeDropdown2" v-click-outside="closeTemplateFilterDropdown2">
                            {{ templateTypeTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="templateTypeDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(templateType, t) in templateTypeFilters" :key="t" :class="{'active' : templateType.title == templateTypeTitle}" @click="sortingTemplateFilter(templateType)">
                                        {{ templateType.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="categoryListDropdown2 = !categoryListDropdown2"  v-click-outside="closeCategoryListDropdown2" v-if="categoryList && categoryList.length">
                            {{ categoryTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr limit_height" :class="categoryListDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(category, c) in categoryList" :key="c" :class="{'active' : category.type == categoryTitle}" @click="sortingCategory(category)">
                                        {{ category.type }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="modal secondary video_player" v-if="tutorial">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr">
                <iframe src="https://www.loom.com/embed/fc211c9ddf0b49fe98dd3e088cee9d8e?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="modal_footer">
                <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
        </div>
    </div>
    <edit-thumbnail-asset v-model="editThumbnail" title="Template" :selected-asset="activeTemplate" :refresh-asset="refreshTemplate" />
    <sort-asset v-model="sortTemplate" title="Template" asset-type="templates" :update-sorting="refreshTemplate" :folder-id="params.folder_id"/>
    <import-share-link v-model="importTemplate" title="Template" :refresh-asset="refreshTemplate" />
    <share-component v-model="shareTemplate" title="Template" :selected-asset="activeTemplate" />
    <move-asset v-model="moveTemplate" title="Template" asset-type="templates" :selected-asset="activeTemplate" :refresh-asset="refreshTemplate" />
    <share-location v-model="shareLocation" title="Template" :selected-asset="activeTemplate" />
    <rename-template v-model="renameTemplate" :template="activeTemplate" />
    <change-category v-model="changeCategory" :categories="categoryList" :templates="selectedTemplate" />
    <create-category v-model="createCategory" />
    <new-template v-model="newTemplate" :refresh-template="refreshTemplate" />
    <edit-template v-model="editTemplate" :template="activeTemplate" :refresh-template="refreshTemplate" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const MoveAsset = defineAsyncComponent(() => import('@/components/MoveAsset'))
    const ShareLocation = defineAsyncComponent(() => import('@/components/ShareLocation'))
    const ChangeCategory = defineAsyncComponent(() => import('@/pages/templates/components/ChangeCategory'))
    const CreateCategory = defineAsyncComponent(() => import('@/pages/templates/components/CreateCategory'))
    const RenameTemplate = defineAsyncComponent(() => import('@/pages/templates/components/RenameTemplate'))
    const NewTemplate = defineAsyncComponent(() => import('@/pages/templates/components/NewTemplate'))
    const EditTemplate = defineAsyncComponent(() => import('@/pages/templates/components/EditTemplate'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: "Templates",

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                newTemplate: false,
                editTemplate: false,
                moment,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                isTyping: false,
                categoryListDropdown: false,
                categoryListDropdown2: false,
                categoryTitle: 'Select Category',
                categoryList: [],
                templateTypeDropdown: false,
                templateTypeDropdown2: false,
                templateTypeTitle: 'All',
                templateTypeFilters: [
                    { title: 'All', type: 'all'},
                    { title: 'Email', type: 'email'},
                    { title: 'SMS', type: 'sms'},
                    { title: 'Voice', type: 'voice'},
                    { title: 'Content', type: 'content'},
                    { title: 'Multi Channel', type: 'multi_channel'},
                ],
                perPageFilter: 6,
                search: '',
                page: 1,
                editThumbnail: false,
                activeTemplate: {},
                selectedTemplate: [],
                isMoreOption: false,
                moreOptionDropdown: 0,
                assetFolder: false,
                assetFolderWatcher: 0,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                sortTemplate: false,
                importTemplate: false,
                changeCategory: false,
                createCategory: false,
                renameTemplate: false,
                moveTemplate: false,
                shareLocation: false,
                shareTemplate: false,
                isLastBreadcrumb: false,
                showContent: false,
                searchField: false,
                mobile_filter: false,
            }
        },

        components: {
            AssetFolder,
            EditThumbnailAsset,
            SortAsset,
            ImportShareLink,
            ShareComponent,
            MoveAsset,
            ShareLocation,
            ChangeCategory,
            CreateCategory,
            RenameTemplate,
            NewTemplate,
            EditTemplate,
        },

        watch: {
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getTemplates(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getTemplates(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.selectedTemplate = []
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.getTemplates(vm.params);
            },

            categories (categories) {
                const vm = this;

                vm.categoryList = vm.filterCategoryType('all');
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                categories: state => state.templateModule.categories,
                templates: state => state.templateModule.templateList,
                loader: state => state.templateModule.templateListLoader,
                params: state => state.templateModule.params,
                isArchived: state => state.folderModule.isArchived,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
                filterCategoryType: 'templateModule/FILTER_CATEGORY_TYPE',
            }),
        },

        mounted () {
            const vm = this;

            vm.emailSendingWarning(vm.user);

            document.getElementById('template_index').onclick = function() {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0;
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.params.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

            vm.setIsPreviousFolder(false);
            // vm.resetDashboardParams();

            if (vm.templates.total == 0 || !vm.templates.total) {
                vm.getTemplates(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            if (vm.params.category_title) {
                vm.categoryTitle = vm.params.category_title;
            }

            if (vm.params.template_title) {
                vm.templateTypeTitle = vm.params.template_title;
            }

            if (vm.params.page) {
                vm.page = vm.params.page;
            }

            // vm.setIsArchivedStatus(false);

            if (!vm.categories.length) {
                vm.getCategories();
            }

            vm.categoryList = vm.categories;
        },

        methods: {
            ...mapActions({
                getCategories: 'templateModule/getCategories',
                getTemplates: 'templateModule/getTemplateList',
                deleteTemplate: 'templateModule/deleteTemplate',
                duplicateTemplate: 'templateModule/duplicateTemplate',
                addBroadcastsDraft: 'templateModule/addBroadcastsDraft',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setDashboardParams: 'templateModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'templateModule/RESET_DASHBOARD_PARAMS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
            }),

            emailSendingWarning (user) {
                if (user.email_status == 0 || user.mailgun_email == '') {
                    const options = Helper.swalWarningOptions('Email sending disabled', 'You can access these features but your email sending is currently disabled. Please contact support to restore your email sending capabilities.', 'Ok')
                     Swal.fire(options);
                }
            },

            togglePerPageFilter () {
                const vm = this;
                vm.categoryListDropdown = false;
                vm.bulkActionDropdown   = false;

                const filter = vm.$refs['template-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closeCategoryListDropdown () {
                const vm = this;

                vm.categoryListDropdown = false;
            },
            closeCategoryListDropdown2 () {
                const vm = this;

                vm.categoryListDropdown2 = false;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['template-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            sortingCategory (category) {
                const vm = this;

                vm.search           = '';
                vm.categoryTitle    = category.type;
                vm.selectedTemplate = [];

                vm.setDashboardParams({ key: 'category_title', value: category.type });
                vm.setDashboardParams({ key: 'category', value: category.type });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.getTemplates(vm.params);
            },

            sortingTemplateFilter (filter) {
                const vm = this;

                vm.categoryTitle     = 'Select Category';
                vm.templateTypeTitle = filter.title;
                vm.search            = '';
                vm.selectedTemplate  = [];

                vm.setDashboardParams({ key: 'category_title', value: 'Select Category' });
                vm.setDashboardParams({ key: 'category', value: '' });
                vm.setDashboardParams({ key: 'template_title', value: filter.title });
                vm.setDashboardParams({ key: 'type', value: filter.type });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.getTemplates(vm.params);

                vm.categoryList = vm.filterCategoryType(filter.type);
            },

            closeTemplateFilterDropdown () {
                const vm = this;

                vm.templateTypeDropdown = false;
            },
            closeTemplateFilterDropdown2 () {
                const vm = this;

                vm.templateTypeDropdown2 = false;
            },

            handlePagination (page) {
                const vm = this;

                vm.selectedTemplate = [];

                vm.setDashboardParams({ key: 'page', value: page });
                vm.getTemplates(vm.params);
            },

            refreshTemplate () {
                const vm = this;

                vm.templateTypeTitle = 'All';
                vm.categoryTitle     = 'Select Category';
                vm.search            = '';
                vm.selectedTemplate  = []

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'category', value: '' });
                vm.setDashboardParams({ key: 'type', value: 'all' });
                vm.setDashboardParams({ key: 'template_title', value: 'All' });
                vm.setDashboardParams({ key: 'category_title', value: 'Select Category' });

                vm.getTemplates(vm.params);
            },

            handleDuplicateTemplate (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate the selected ${ids.length > 1 ? 'templates' : 'template'}`);

                    options.preConfirm = function () {
                                            return vm.duplicateTemplate({ ids }).then((result) => {
                                                if (result) {
                                                    vm.search           = '';
                                                    vm.selectedTemplate = [];

                                                    vm.setDashboardParams({ key: 'search', value: '' });
                                                    vm.getTemplates(vm.params);
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one template');
                }
            },

            handleDeleteTemplate (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ids.length > 1 ? 'these templates' : 'this template'}`, 'Delete');

                    options.preConfirm = function () {
                                            return vm.deleteTemplate({ ids }).then((result) => {
                                                if (result) {
                                                    vm.refreshTemplate();
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one template');
                }
            },

            moreOption (id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption   = false;
                }, 100);
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshTemplate();
            },

            handleChangeCategory () {
                const vm = this;

                if (vm.params.type == 'all' || vm.params.type == 'multi_channel') {
                    return Toastr.error('Please select any one template type form Email, SMS, Voice and Content');
                }

                vm.changeCategory = true;
            },

            handleBroadcastDraft (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to add as broadcast draft.`);

                    options.preConfirm = function () {
                                            return vm.addBroadcastsDraft({ ids }).then((result) => {
                                                if (result) {
                                                    vm.refreshTemplate();
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one template');
                }
            },

            handleShareTemplate (template) {
                const vm = this;

                if (template.share_count == 0) {
                    vm.shareTemplate = true;
                    vm.activeTemplate = template;
                } else if (template.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            }
        }
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    
    .dropdown_wpr ul li span {
        font-size: 10px;
        font-weight: 500;
        color: #757575;
    }

    .playbook_list  {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list > li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .category {
        padding: 4px 12px;
        background: #e9e9e9;
        border-radius: 12px;
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info > ul > li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
    }

    .plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    @keyframes dropMove2  {

        from  {margin-bottom: 10px; opacity: 0;}

        to  {margin-bottom: 1px; opacity: 1;}
    }


    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table th:nth-child(2) {
        text-align: left;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        /* padding-left: 15px; */
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i {
        color: #2f7eed;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        color: #121525;
        width:25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }


    .small_preview .modal_container {
        height: 640px;
    }

    .modal.small_preview .modal_header {
        padding: 20px 30px;
    }

    .modal.small_preview .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .preview_data {
        display: flex;
        flex-wrap: wrap;
        margin: 40px -10px;
    }

    .preview_data li {
        padding: 10px;
        flex: 1 0 33.333%;
        background: #fff;
    }

    .preview_data .data_wpr {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 15px;
    }

    .preview_data .data_wpr h6 {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 7px;
        font-weight: 500;
        color: #121525;
    }

    .preview_data .data_wpr h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .preview_data .data_wpr .form_grp .multiselect {
        background: #fff;
    }

    .check_option {
        display: flex;
        padding: 5px 0;
        gap: 15px;
    }

    .check_option li {
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding: 15px 20px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    .check_option li h5 {
        font-size: 14px;
        line-height: 17px;
        margin-left: 10px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    .share_playbook .dropdown_wpr ul {
        max-height: 210px;
        overflow-y: scroll;
    }

    .share_playbook .dropdown_wpr ul::-webkit-scrollbar  {
      width: 4px;
    }

    .share_playbook .dropdown_wpr ul::-webkit-scrollbar-thumb  {
      background-color: #d3d3d3;
      border-radius: 2px;
    }

    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 20%;
    }

    .analytics_card {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card .score_circle {
        width: 50px;
        height: 50px;
        padding: 3px;
    }

    .analytics_card .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
    }

    .analytics_card h4 {
        font-size: 12px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 767px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
